import Box from 'components/Box/Box';
import { BoxProps } from 'components/Box/types';
import PlayNowButton from 'components/Button/PlayNowButton';
import Text from 'components/Text/Text';
import { ColumnCenter } from 'layout/Components/Column';
import { RowCenter } from 'layout/Components/Row';
import React from 'react';
import styled from 'styled-components';

const HomeFooter: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <BoxWrapper {...props}>
      <StyledContent>
        <ColumnCenter width="max-content !important">
          <Text
            letterSpacing={0}
            fontSize={['10px', '12px', '', '16px', '24px', '', '40px']}
            mb={['12px', '24px', '', '32px', '', '40px', '', '80px']}
            fontWeight={500}
          >
            Hurry and get in on the action!
          </Text>
          <PlayNowButton
            fontSize={['12px', '14px', '', '', '28px', '', '34px', '', '36px']}
            lineHeight={['14px', '', '', '28px', '', '34px', '', '46px']}
            maxWidth={['100px', '140px', '', '', '240px', '', '360px']}
            height={['40px', '', '', '90px']}
          />
        </ColumnCenter>
      </StyledContent>
      <Text fontSize={['10px', '12px', '', '14px', '', '16px']} mt={['10px', '', '', '-2%']}>
        Copyright © 2022 HunnyPoker
      </Text>
    </BoxWrapper>
  );
};

const BoxWrapper = styled(RowCenter)`
  width: 100%;
  height: 100%;
  padding-top: 41.67%;
  position: relative;
  background-image: url('/images/footer.png');
  background-size: 100% 100%;
`;

const StyledContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
`;

export default HomeFooter;
